import whiteboardCSS from './whiteboard.css';

class WhiteboardLayer {

  static color = 'orange';
  static penSize = 1;
  static opacity = 1;

  parentElem;
  canvas;
  ctx;

  pointerIsDown = false;
  lastPoint = { x : null, y : null};

  constructor(parentElem) {
    this.parentElem = parentElem;
    
    this.canvas = document.createElement('canvas');
    this.canvas.id = this.parentElem.id + '-whiteboard';
    this.canvas.classList.add('whiteboard');
    this.parentElem.appendChild(this.canvas);
    this.canvas.width = this.parentElem.offsetWidth;
    this.canvas.height = this.parentElem.offsetHeight;
    this.ctx = this.canvas.getContext('2d');
    this.ctx.lineCap = 'round';
    this.ctx.lineJoin = 'round';
    this.canvas.addEventListener('pointerdown', evt => this.#onPointerdown(evt), true);
    this.canvas.addEventListener('touchstart', evt => this.#onPointerdown(evt), true);
    this.canvas.addEventListener('pointerup', evt => this.#onPointerup(evt), true);
    this.canvas.addEventListener('touchend', evt => this.#onPointerup(evt), true);
    this.canvas.addEventListener('pointermove', evt => this.#onPointermove(evt), true);
    this.canvas.addEventListener('touchmove', evt => this.#onPointermove(evt), true);
    document.addEventListener('translucentWhiteboard', evt => this.#onTranslucentWhiteboard(evt));
    this.canvas.addEventListener('contextmenu', evt => evt.preventDefault());
    this.canvas.addEventListener('remoteWhiteboard', evt => this.#onRemote(evt));

    if (this.parentElem.classList.contains('whiteboard-filled') === true) {
      this.fillWhiteboard('#fffffc')
    }
    document.addEventListener('changeWhiteboardPen', 
      evt => this.#changePen(evt.detail.color, evt.detail.opacity, evt.detail.penSize));

    document.addEventListener('clearVisibleWhiteboard', evt => this.#clearIfVisible());
  }

  fillWhiteboard(color) {
      this.ctx.globalCompositeOperation = "source-source-over";
      this.ctx.fillStyle = color;
      this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);
  }

  #distanceFormLastPoint(x, y) {
    return Math.sqrt(
      Math.abs((this.lastPoint.x-x)*(this.lastPoint.x-x))+
      Math.abs((this.lastPoint.y-y)*(this.lastPoint.y-y))
    );
  }

  #changePen(color, opacity, penSize) {
    if (color !== undefined) WhiteboardLayer.color = color;
    if (opacity !== undefined) WhiteboardLayer.opacity = opacity;
    if (penSize !== undefined) WhiteboardLayer.penSize = penSize;  
  }

  #onRemote(evt) {
    const action = evt.detail.action;
    if (action === 'changeWhiteboardPen') {
      this.#changePen(evt.detail.color, evt.detail.opacity, evt.detail.penSize);
    } else if (action === 'startWhiteboardStroke') {
      this.#changePen(evt.detail.color, evt.detail.opacity, evt.detail.penSize);
      this.startDrawing(evt.detail.x, evt.detail.y);
    } else if (action === 'whiteboardStroke') {
      this.draw(evt.detail.x, evt.detail.y);
    } else if (action === 'clearWhiteboard') {
      this.clear();
    }
  }

  #onTranslucentWhiteboard(evt) {
    this.canvas.classList.toggle('translucent');
  }

  /**
   * Extracts the coordinate of a valid user interaction with the canvas.
   * 
   * @param {TouchEvent | PointerEvent} evt 
   * @return null if not applicable or {x, y} with the point clicked
   */
  #getPointCoordinates(evt) {
    let coords = null;
    // We ignore touch events from pointer listener
    if (evt.type === 'pointerdown' || evt.type === 'pointerup' || evt.type === 'pointermove') {
      if (evt.pointerType === 'touch') {
        return null;
      }
      coords = { x : evt.offsetX, y : evt.offsetY};
    } else if (evt.type === 'touchstart' || evt.type === 'touchend' || evt.type === 'touchmove') {
      // We ignore touch events from touch listeners, unless they are generated by a pencil
      // Apparently, touch event with BIG radiusX are originated by a pen/pencil
      if (evt.changedTouches[0].radiusX < 1000) return null;

      const canvasWidth = this.canvas.offsetWidth; // (1920 in 16:9)
      const canvasHeight = this.canvas.offsetHeight; // (900 in 16:9)
      const box = this.canvas.getBoundingClientRect();

      // transforms touch event window-based coordinates into element coords
      // esto no debería ser tan jodidamente difícil 💀
      const x = (evt.changedTouches[0].clientX - box.left) * (canvasWidth / box.width);
      const y = (evt.changedTouches[0].clientY - box.top) * (canvasHeight / box.height);

      coords = { x, y };
    }
    
    return coords;
  }

  /**
   * 
   * @fires  clearWhiteboard if actually it has started
   * @fires  startWhiteboardStroke if actually it has started
   */
  #onPointerdown(evt) {
    const coords = this.#getPointCoordinates(evt);
    if (coords === null) return;

    // Secondary button clears whiteboard
    if (evt.buttons === 2) {
      this.clear();
      evt.preventDefault();
      this.#dispatchEvent('clearWhiteboard', {
        source : this.canvas
      });
    } else {
      this.pointerIsDown = true;
      this.startDrawing(coords.x, coords.y)
      this.lastPoint.x = coords.x;
      this.lastPoint.y = coords.y;
      this.#dispatchEvent('startWhiteboardStroke', {
        source : this.canvas,
        x : coords.x,
        y : coords.y,
        color : WhiteboardLayer.color,
        penSize : WhiteboardLayer.penSize,
        opacity : WhiteboardLayer.opacity
      });
    }
  }

  /**
   * 
   * @fires  endWhiteboardStroke
   */
  #onPointerup(evt) {
    const coords = this.#getPointCoordinates(evt);
    if (coords === null) return;
    // Ignore secodary button
    if (evt.buttons === 2) return;

    this.pointerIsDown = false;
    this.endDrawing();
    this.#dispatchEvent('endWhiteboardStroke', {
      source : this.canvas
    });
  }

  /**
   * 
   * @fires  whiteboardStroke
   */
  #onPointermove(evt) {
    if (this.pointerIsDown === false) return;

    const coords = this.#getPointCoordinates(evt);
    if (coords === null) return;

    const distance = this.#distanceFormLastPoint(coords.x, coords.y);
    if (distance < 2) return;

    this.draw(coords.x, coords.y);
    this.lastPoint.x = coords.x;
    this.lastPoint.y = coords.y;
    this.#dispatchEvent('whiteboardStroke', {
      source : this.canvas,
      x : coords.x,
      y : coords.y,
    });
  }

  #clearIfVisible() {
    if (this.parentElem.classList.contains('active') === true) {
      this.clear();
      this.#dispatchEvent('clearWhiteboard', {
        source : this.canvas
      });      
    }
  }

  startDrawing(x, y) {
    if (WhiteboardLayer.color == 'eraser') {
      this.ctx.globalCompositeOperation = "destination-out";
      this.ctx.lineWidth = 30;
    } else {
      this.ctx.globalCompositeOperation = "lighter";
      this.ctx.strokeStyle = WhiteboardLayer.color;
      this.ctx.lineWidth = WhiteboardLayer.penSize;
    }
    if (WhiteboardLayer.opacity === 1) {
      this.ctx.shadowBlur = 1;
      this.ctx.shadowColor = WhiteboardLayer.color;  
    } else {
      this.ctx.shadowBlur = 0;
    }
    this.ctx.globalAlpha = WhiteboardLayer.opacity;
    this.ctx.beginPath();
    this.ctx.moveTo(x, y);
  }

  draw(x, y) {
    this.ctx.lineTo(x, y);
    this.ctx.stroke();
  }

  endDrawing() {
    this.ctx.closePath();
  }

  clear() {
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
  }

  #dispatchEvent(eventName, detail) {
    let event = new CustomEvent(eventName, { detail });
    document.dispatchEvent(event);
  }

}

export default WhiteboardLayer;